<template>
  <div>
    <div
      :style="`background-image: url('${
        $root.event && $root.event.pageSettings
          ? $root.event.pageSettings.bgImgUrl
          : defaultBackgroundImg
      }')`"
      class="landing"
    >
      <div v-if="$root.event" class="container is-max-widescreen">
        <div style="display: flex" v-if="!$root.event.regLimitFull">
          <div class="columns is-centered" style="width: 100%">
            <!-- eslint-disable max-len -->
            <div
              v-if="
                $root.event.pageSettings &&
                ($root.event.pageSettings.logoUrl ||
                  $root.event.pageSettings.landingContent)
              "
              class="column is-half-tablet is-three-fifths-widescreen px-5"
            >
              <img
                v-if="
                  $root.event.pageSettings && $root.event.pageSettings.logoUrl
                "
                :src="$root.event.pageSettings.logoUrl"
              />
              <div
                class="mt-1 mb-3"
                v-html="$root.event.pageSettings.landingContent"
              />
            </div>

            <div
              :class="[
                $root.event.pageSettings &&
                ($root.event.pageSettings.logoUrl ||
                  $root.event.pageSettings.landingContent)
                  ? ' is-half-tablet is-two-fifths-widescreen'
                  : 'is-full-tablet is-three-fifths-widescreen no-event-content',
              ]"
              class="column"
            >
              <!--
              <h1 class="title has-text-centered">
                {{ $root.event.title }}
              </h1>
              -->

              <div class="card">
                <div class="card-content">
                  <h3 class="is-size-5 has-text-weight-bold title">
                    Mesut Süre ile İlişki Testi Etkinlik Kayıt Formu
                  </h3>
                  <FormBase />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="fs-wrapper" v-if="$root.event.regLimitFull">
          <div class="card thanks">
            <div class="card-content">
              <h4>Etkinliğimiz maksimum katılımcı sayısına ulaşmıştır.</h4>
              <p style="font-weight: bold">İlginize teşekkür ederiz.</p>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="no-event-fount">{{ $t("no_event_found") }}</div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import FormBase from '@/components/regForm/FormBase.vue';

export default {
  name: 'Home',
  components: {
    FormBase,
  },
  data() {
    return {
      isReady: false,
      isLoading: true,
      defaultBackgroundImg: '',
    };
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style>
html {
  background-color: #f5f5f5;
}
.columns {
  margin-top: 128px;
}

.no-event-content {
  max-width: 830px !important;
  margin: auto;
}

.is-size-5 {
  font-size: 1.25rem !important;
}

.label,
.control-label {
  font-size: 0.875rem !important;
}

.field-content p {
  font-size: 0.875rem !important;
}

.field-content h6 {
  font-size: 1rem !important;
}

.field-content h5 {
  font-size: 1.25rem !important;
}

.field-content h4 {
  font-size: 1.5rem !important;
}

.field-content h3 {
  font-size: 1.75rem !important;
}
.field-content h2 {
  font-size: 2rem !important;
}

.field-content h1 {
  font-size: 2.25rem !important;
}

.landing {
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 0%;
  background-attachment: fixed;
}
.landing .select,
.landing .select select {
  width: 100% !important;
}
.no-event-fount {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}
</style>
