var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.field.is_shown)?_c('ValidationProvider',{attrs:{"vid":_vm.vid,"name":_vm.getInputLabel(_vm.$attrs),"rules":_vm.getValidationRules(_vm.field),"mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',_vm._b({attrs:{"type":{
      'is-danger': errors[0],
      'is-success': valid && _vm.innerValue.length > 0,
    },"message":errors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.getFieldTag("checkbox", _vm.field) || _vm.getFieldTag("html_content", _vm.field) ? "" : _vm.getInputLabel(_vm.$attrs))+" "),(
          _vm.field.required &&
          !(
            _vm.getFieldTag('checkbox', _vm.field) ||
            _vm.getFieldTag('html_content', _vm.field)
          )
        )?_c('span',{staticClass:"has-text-danger"},[_vm._v("*")]):_vm._e(),(!_vm.getFieldTag('checkbox', _vm.field))?_c('span',{staticClass:"field-help-text",domProps:{"innerHTML":_vm._s(_vm.field.helpText)}}):_vm._e()]},proxy:true}],null,true)},'b-field',_vm.$attrs,false),[(_vm.getFieldTag('input', _vm.field))?_c('b-input',_vm._b({model:{value:(_vm.innerValue),callback:function ($$v) {_vm.innerValue=$$v},expression:"innerValue"}},'b-input',_vm.$attrs,false)):_vm._e(),(_vm.getFieldTag('textarea', _vm.field))?_c('b-input',_vm._b({attrs:{"type":"textarea"},model:{value:(_vm.innerValue),callback:function ($$v) {_vm.innerValue=$$v},expression:"innerValue"}},'b-input',_vm.$attrs,false)):_vm._e(),(_vm.getFieldTag('password', _vm.field))?_c('b-input',_vm._b({attrs:{"type":"password"},model:{value:(_vm.innerValue),callback:function ($$v) {_vm.innerValue=$$v},expression:"innerValue"}},'b-input',_vm.$attrs,false)):_vm._e(),(_vm.getFieldTag('checkbox', _vm.field))?_c('b-checkbox',{model:{value:(_vm.innerValue),callback:function ($$v) {_vm.innerValue=$$v},expression:"innerValue"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.field.helpText)}})]):_vm._e(),(_vm.getFieldTag('select', _vm.field))?_c('b-select',_vm._b({model:{value:(_vm.innerValue),callback:function ($$v) {_vm.innerValue=$$v},expression:"innerValue"}},'b-select',_vm.$attrs,false),_vm._l((_vm.getSelectBoxOptions(_vm.field)),function(option){return _c('option',{key:option,domProps:{"value":option}},[_vm._v(" "+_vm._s(option)+" ")])}),0):_vm._e(),(_vm.getFieldTag('html_content', _vm.field))?_c('div',{staticClass:"field-content",domProps:{"innerHTML":_vm._s(_vm.field.content)}}):_vm._e()],1)]}}],null,false,563796557)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }