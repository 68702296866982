const messages = {
  en: {
    cancel: 'Cancel',
    send: 'Send',
    sending: 'Sending...',
    name: 'First Name',
    last_name: 'Last Name',
    error: 'Error!',
    okey: 'Okay',
    email: 'Email',
    event_password: 'Event Password',
    registration: 'Registration',
    city: 'City',
    'country/region': 'Country/Region',
    'zip/postal_code': 'Zip/Postal Code',
    'state/province': 'State/Province',
    phone: 'Phone',
    address: 'Address',
    speciality: 'Speciality',
    organization: 'Organization',
    job_title: 'Job Title',
    please_wait: 'Please Wait',
    no_event_found: 'No Event Found',
    join_now: 'Join Now',
    page_language: 'Website Language',
    type_your_question: 'Please type your question',
    this_event_is_not_started: 'This event is not started yet.',
    this_event_completed: 'This event has been concluded.',
    registration_required:
      'Registration is required for this event. Redirecting to registration page.',
    thankyou_registration: 'Thank you for registering our webcast!',
    link_sent: 'A private link for the webcast has been sent to your email.',
    link_warning:
      'Please check your mailbox. If you do not receive the confirmation message within a few minutes, please check your Spam folder just in case the confirmation email got delivered there instead of your inbox.',
  },
};

export default messages;
