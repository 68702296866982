var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"landing",style:(("background-image: url('" + (_vm.$root.event && _vm.$root.event.pageSettings
        ? _vm.$root.event.pageSettings.bgImgUrl
        : _vm.defaultBackgroundImg) + "')"))},[(_vm.$root.event)?_c('div',{staticClass:"container is-max-widescreen"},[(!_vm.$root.event.regLimitFull)?_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"columns is-centered",staticStyle:{"width":"100%"}},[(
              _vm.$root.event.pageSettings &&
              (_vm.$root.event.pageSettings.logoUrl ||
                _vm.$root.event.pageSettings.landingContent)
            )?_c('div',{staticClass:"column is-half-tablet is-three-fifths-widescreen px-5"},[(
                _vm.$root.event.pageSettings && _vm.$root.event.pageSettings.logoUrl
              )?_c('img',{attrs:{"src":_vm.$root.event.pageSettings.logoUrl}}):_vm._e(),_c('div',{staticClass:"mt-1 mb-3",domProps:{"innerHTML":_vm._s(_vm.$root.event.pageSettings.landingContent)}})]):_vm._e(),_c('div',{staticClass:"column",class:[
              _vm.$root.event.pageSettings &&
              (_vm.$root.event.pageSettings.logoUrl ||
                _vm.$root.event.pageSettings.landingContent)
                ? ' is-half-tablet is-two-fifths-widescreen'
                : 'is-full-tablet is-three-fifths-widescreen no-event-content' ]},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-content"},[_c('h3',{staticClass:"is-size-5 has-text-weight-bold title"},[_vm._v(" Mesut Süre ile İlişki Testi Etkinlik Kayıt Formu ")]),_c('FormBase')],1)])])])]):_vm._e(),(_vm.$root.event.regLimitFull)?_c('div',{staticClass:"fs-wrapper"},[_vm._m(0)]):_vm._e()]):_c('div',{staticClass:"no-event-fount"},[_vm._v(_vm._s(_vm.$t("no_event_found")))])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card thanks"},[_c('div',{staticClass:"card-content"},[_c('h4',[_vm._v("Etkinliğimiz maksimum katılımcı sayısına ulaşmıştır.")]),_c('p',{staticStyle:{"font-weight":"bold"}},[_vm._v("İlginize teşekkür ederiz.")])])])}]

export { render, staticRenderFns }